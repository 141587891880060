import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../components/global/layout/Layout';
import WrapperOne from '../components/global/wrappers/WrapperOne';
import InfoboxTwo from '../components/global/InfoboxTwo';
import Padding from '../components/global/Padding/Padding';
import SectionTextBox from '../components/global/section-textbox/SectionTextBox';
import Button from '../components/global/Button';
import Customers from '../components/global/customers/Customers';
import FeatureSnippetThree from '../components/global/FeatureSnippetThree';
import Faq from '../components/global/Faq';
import SiriWave from '../../utils/siriwave';
import RealEstateCTA from '../components/global/CTA/RealEstateCTA';
import { setColors, checkLink } from '../../utils/helpers';
import SEO from '../components/global/seo';
import * as S from '../components/newly-developed-pages/style';

const customStyles = {
	content: {
		position: 'relative',
		display: 'inline-block',
		margin: 'auto',
		inset: '0',
		boxSizing: 'border-box',
		outline: '0',
		padding: '0',
		border: 'none',
		verticalAlign: 'middle',
		width: '100%',
		maxWidth: '900px',
	},
	overlay: {
		backgroundColor: setColors.modalOverLayColor,
		zIndex: '1050',
		overflowX: 'hidden',
		overflowY: 'auto',
		textAlign: 'center',
		padding: '0 6px',
		display: 'flex',
	},
};

const RealEstateAgentTemplate = ({ data }) => {
	const {
		heroHeadingText,
		heroTitle,
		heroSubtitle,
		heroButton,
		heroIcon,
		heroImage,
		faqTitle,
		faqSubtitle,
		faqContent,
		section1title,
		section1text,
		section1image,
		section1buttonText,
		section1buttonLink,
		section2title,
		section2images,
		section3mainImages,
		section3logos,
		section3content,
		section7title,
		section7subtitle,
		section7buttonText,
		section7buttonLink,
		seoTitle,
		seoDescription,
		seoImage,
		seoJsonSchema,
	} = data.contentfulRealEstateAgentTemplate;
	const [modal, setModal] = useState(false);
	const handleOpenModal = () => {
		setModal(!modal);
	};
	const generateEmbedLink = link => {
		const url = new URL(link);
		const videoId = url.searchParams.get('v');
		return `https://www.youtube.com/embed/${videoId}?autoplay=1`;
	};
	const generateButton = (link, text) => {
		const linkType = checkLink(link);
		if (linkType === 2) {
			return (
				<Button btnColor={setColors.themePurple} to={link}>
					{text}
				</Button>
			);
		} else if (linkType === 1) {
			return (
				<Button
					btnColor={setColors.themePurple}
					as="a"
					href={link}
					target="_self"
				>
					{text}
				</Button>
			);
		} else if (linkType === 0) {
			const url = new URL(link);
			if (url.hostname === 'www.youtube.com') {
				return (
					<Button
						btnColor={setColors.themePurple}
						as="a"
						href={link}
						target="_blank"
						rel="noopener noreferrer"
						onClick={e => {
							e.preventDefault();
							handleOpenModal();
						}}
					>
						{text}
					</Button>
				);
			} else {
				return (
					<Button
						btnColor={setColors.themePurple}
						as="a"
						href={link}
						target="_blank"
						rel="noopener noreferrer"
					>
						{text}
					</Button>
				);
			}
		}
	};
	useEffect(() => {
		new SiriWave({
			container: document.getElementById('siri-container'),
			width: 640,
			height: 200,
			style: 'ios',
			color: setColors.black,
			frequency: 6,
			amplitude: 1.5,
			curveDefinition: [
				{
					attenuation: -2,
					lineWidth: 1,
					opacity: 0.6,
					color: '229,231,233',
				},
				{
					attenuation: 2,
					lineWidth: 1,
					opacity: 0.8,
					color: '255,159,148',
				},
				{
					attenuation: 1,
					lineWidth: 1.5,
					opacity: 1,
					color: '109,111,189',
				},
			],
		}).setSpeed(0.03);
	}, []);
	return (
		<Layout>
			<S.HeroWrapper>
				<InfoboxTwo
					heading={heroHeadingText}
					title={heroTitle}
					subtitle={heroSubtitle}
					icon={heroIcon}
					image={heroImage}
					jsonButton={heroButton}
					isHeroHeader
				/>
				<S.SiriWrapper>
					<div id="siri-container"></div>
				</S.SiriWrapper>
			</S.HeroWrapper>
			<S.SectionWrapper>
				<S.BgWrapper>
					<Padding padding="100" />
					<S.ContentWrapper>
						<WrapperOne wrapperClass="disable-grid-mobile">
							<div className="section-header">
								<SectionTextBox
									title={section1title}
									richTextContent={section1text}
								/>
							</div>
							<div style={{ padding: '30px 15px' }}>
								<Img fluid={section1image.fluid} alt={section1image.title} />
							</div>
							<div
								style={{
									width: '100%',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									padding: '10px 0 0 0',
								}}
							>
								{generateButton(section1buttonLink, section1buttonText)}
							</div>
							<Padding padding="100" />
						</WrapperOne>
					</S.ContentWrapper>
					<Padding padding="70" />
				</S.BgWrapper>
				<S.StyledReactModal
					isOpen={modal}
					closeTimeoutMS={500}
					shouldFocusAfterRender={false}
					shouldCloseOnOverlayClick={true}
					style={customStyles}
					onRequestClose={handleOpenModal}
					portalClassName="portal-class"
				>
					<div className={`modal-content modal-default ${modal && 'open'}`}>
						<button onClick={handleOpenModal} className="close-button">
							×
						</button>
						<iframe
							title="embeddedYoutubeVideo"
							src={generateEmbedLink(section1buttonLink)}
							allow="autoplay;fullscreen;"
							frameborder="0"
							allowfullscreen="true"
							webkitallowfullscreen="true"
							mozallowfullscreen="true"
						></iframe>
					</div>
				</S.StyledReactModal>
			</S.SectionWrapper>
			<S.SectionWrapper>
				<S.CustomerWrapper>
					<Customers
						section2Images={section2images}
						section2Title={section2title}
					/>
				</S.CustomerWrapper>
			</S.SectionWrapper>
			{section3mainImages.map((mainImage, index) => (
				<S.SectionWrapper key={index}>
					<WrapperOne disableBottomMargin="true">
						<S.FeatureSnippetThreeHolder>
							<FeatureSnippetThree
								image={mainImage}
								invert={index % 2 === 0 ? true : false}
								logo={section3logos[index]}
								content={section3content[index]}
							/>
						</S.FeatureSnippetThreeHolder>
					</WrapperOne>
				</S.SectionWrapper>
			))}
			<S.SectionWrapper>
				<S.FaqWrapper>
					<S.BGWrapperTwo>
						<Padding padding="100" />
						<Padding padding="100" />
						<WrapperOne disableBottomMargin="true">
							<Faq
								title={faqTitle}
								subtitle={faqSubtitle}
								content={faqContent}
								maxCount="10"
								largeTitle="true"
								primaryColor={setColors.themePurple}
							/>
						</WrapperOne>
						<Padding padding="120" />
					</S.BGWrapperTwo>
				</S.FaqWrapper>
			</S.SectionWrapper>
			<S.SectionWrapper>
				<WrapperOne disableBottomMargin="true">
					<RealEstateCTA
						title={section7title}
						subtitle={section7subtitle}
						buttonText={section7buttonText}
						buttonLink={section7buttonLink}
					/>
				</WrapperOne>
			</S.SectionWrapper>
			<S.GlobalStyle />
			<SEO
				title={seoTitle}
				description={seoDescription}
				article={true}
				image={seoImage}
				seoJsonSchema={seoJsonSchema}
			/>
		</Layout>
	);
};

export default RealEstateAgentTemplate;

export const pageQuery = graphql`
	query($slug: String) {
		contentfulRealEstateAgentTemplate(slug: { eq: $slug }) {
			heroHeadingText
			heroTitle
			heroSubtitle
			heroButton {
				buttonText
				buttonLink
			}
			heroIcon {
				fixed(height: 30, width: 128, quality: 30) {
					...GatsbyContentfulFixed
				}
				title
			}
			heroImage {
				fluid(quality: 70) {
					...GatsbyContentfulFluid
				}
				title
			}
			faqTitle
			faqSubtitle {
				raw
			}
			faqContent {
				question
				answer {
					type
					content
				}
			}
			section1title
			section1text {
				raw
			}
			section1image {
				fluid(quality: 70) {
					...GatsbyContentfulFluid
				}
				title
			}
			section1buttonText
			section1buttonLink
			section2title
			section2images {
				fluid(quality: 10) {
					...GatsbyContentfulFluid
				}
				title
			}
			section3mainImages {
				title
				fluid {
					...GatsbyContentfulFluid
				}
			}
			section3logos {
				title
				fixed {
					...GatsbyContentfulFixed
				}
				file {
					details {
						image {
							height
							width
						}
					}
				}
			}
			section3content {
				title
				subtitle
				linkText
				link
			}
			section7title
			section7subtitle {
				raw
			}
			section7buttonText
			section7buttonLink
			seoTitle
			seoDescription
			seoImage {
				title
				file {
					url
					details {
						image {
							height
							width
						}
					}
				}
			}
			seoJsonSchema {
				internal {
					content
				}
			}
		}
	}
`;
